import { MAX_CHARACTER_LIMIT, CHART_TYPES_FOR_DISPLAY, METRICS_FOR_DISPLAY } from "@/constants"
import ThemisInput from "@/components/shared/input"
import {
  ISSUE_PROPERTIES_FOR_CHART,
  CHART_DIMENSION_TYPES,
  ALLOWED_FIELD_TYPES_FOR_CHART_DIMENSIONS_AND_SUBDIVISIONS,
  CHART_TYPES,
  TIME_FRAMES_FOR_DISPLAY
} from "@/constants"

export default {
  name      : "ChartForm",
  components: {
    ThemisInput
  },
  data() {
    return {
      chart: {
        name         : undefined,
        customViewId : this.pCustomViewId,
        savedFilterId: undefined,
        type         : undefined,
        metric       : undefined,
        dimension    : undefined,
        subdivision  : undefined
      },
      timeframe              : undefined,
      isChartNameDuplicate   : false,
      chartNameCharacterLimit: MAX_CHARACTER_LIMIT.CHART_NAME,
      issuePropertyItems     : [{
        header: this.$t("2291")
      }, {
        name : this.$t("2292"),
        value: {
          type    : CHART_DIMENSION_TYPES.PROPERTY,
          selected: ISSUE_PROPERTIES_FOR_CHART.ASSIGNEE
        }
      }, {
        name : this.$t("2293"),
        value: {
          type    : CHART_DIMENSION_TYPES.PROPERTY,
          selected: ISSUE_PROPERTIES_FOR_CHART.CHANNELS
        }
      }, {
        name : this.$t("2305"),
        value: {
          type    : CHART_DIMENSION_TYPES.PROPERTY,
          selected: ISSUE_PROPERTIES_FOR_CHART.DATE
        }
      }, {
        name : this.$t("2294"),
        value: {
          type    : CHART_DIMENSION_TYPES.PROPERTY,
          selected: ISSUE_PROPERTIES_FOR_CHART.DOMAINS
        }
      }, {
        name : this.$t("2295"),
        value: {
          type    : CHART_DIMENSION_TYPES.PROPERTY,
          selected: ISSUE_PROPERTIES_FOR_CHART.REPORT_LANGUAGE
        }
      }, {
        name : this.$t("2296"),
        value: {
          type    : CHART_DIMENSION_TYPES.PROPERTY,
          selected: ISSUE_PROPERTIES_FOR_CHART.SOURCE
        }
      }, {
        name : this.$t("2297"),
        value: {
          type    : CHART_DIMENSION_TYPES.PROPERTY,
          selected: ISSUE_PROPERTIES_FOR_CHART.STATUS
        }
      }]
    }
  },
  props: {
    pCustomViewId              : Number,
    pFilters                   : Array,
    pIsAddingChart             : Boolean,
    pIsUpdatingChart           : Boolean,
    pChartAddError             : Object,
    pChartNameUpdateError      : Object,
    pIssueFields               : Array,
    pFields                    : Array,
    pFormTemplates             : Array,
    pIssueFormTemplates        : Array,
    pFormTemplateConfigurations: Array,
    pIsEditMode                : Boolean,
    pChartToEdit               : Object
  },
  emits   : ["closeChartForm", "addChart", "updateChart", "resetChartAddError", "resetChartNameUpdateError"],
  computed: {
    creatingChartsHelpCenterUrl() {
      return process.env.VUE_APP_CREATE_CHARTS_HELP_URL
    },
    filters() {
      return this.pFilters
    },
    chartTypes() {
      return CHART_TYPES_FOR_DISPLAY.map(chartType => ({
        ...chartType,
        text: this.$t(chartType.text)
      }))
    },
    metrics() {
      return METRICS_FOR_DISPLAY.map(metric => ({
        ...metric,
        text: this.$t(metric.text)
      }))
    },
    timeframes(){
      return TIME_FRAMES_FOR_DISPLAY.map(timeframe => ({
        ...timeframe,
        text: this.$t(timeframe.text)
      }))
    },
    fieldsMap() {
      const fieldsMap = {}
      for (const field of this.pFields) {
        fieldsMap[field.id] = field
      }
      return fieldsMap
    },
    issueFieldData() {
      return this.pIssueFields
        .filter(issueField => {
          const field = this.fieldsMap[issueField.fieldId]
          return ALLOWED_FIELD_TYPES_FOR_CHART_DIMENSIONS_AND_SUBDIVISIONS.includes(field.type)
        })
        .map(issueField => ({
          name : this.fieldsMap[issueField.fieldId].label,
          value: {
            type    : CHART_DIMENSION_TYPES.FIELD,
            selected: issueField.fieldId
          }
        }))
    },
    formTemplateIdMultiInstanceMap() {
      const formTemplateIdMultiInstanceMap = {}
      for (const issueFormTemplate of this.pIssueFormTemplates) {
        formTemplateIdMultiInstanceMap[issueFormTemplate.formTemplateId] = issueFormTemplate.multiInstance
      }
      return formTemplateIdMultiInstanceMap
    },
    issueFormTemplates() {
      return this.pFormTemplates.filter(formTemplate => !formTemplate.reportForm &&
        this.formTemplateIdMultiInstanceMap[formTemplate.id] === false
      )
    },
    reportFormTemplates() {
      return this.pFormTemplates.filter(formTemplate => formTemplate.reportForm)
    },
    reportFormData() {
      return this.processFormTemplates(this.reportFormTemplates)
    },
    issueFormData() {
      return this.processFormTemplates(this.issueFormTemplates)
    },
    dimensionItems() {
      const dimensionItems = []
      dimensionItems.push(...this.issuePropertyItems)
      dimensionItems.push({
        divider: true
      }, {
        header: this.$t("2298")
      })
      if (this.issueFieldData.length) {
        dimensionItems.push(...this.issueFieldData)
      }
      dimensionItems.push({
        divider: true
      }, {
        header: this.$t("2299")
      })
      if (this.reportFormData.length) {
        dimensionItems.push(...this.reportFormData)
      }
      dimensionItems.push({
        divider: true
      }, {
        header: this.$t("2300")
      })
      if (this.issueFormData.length) {
        dimensionItems.push(...this.issueFormData)
      }
      return dimensionItems
    },
    subdivisionItems() {
      const subdivisionItems       = []
      const selectedDimensionValue = this.chart.dimension?.selected
      const selectedDimensionType  = this.chart.dimension?.type
      subdivisionItems.push(...this.issuePropertyItems.filter(item => {
        return item.name !== this.$t("2305") && (!selectedDimensionValue || (item.value?.selected !== selectedDimensionValue || item.value?.type !== selectedDimensionType))
      }))

      subdivisionItems.push({
        divider: true
      }, {
        header: this.$t("2298")
      })
      if (this.issueFieldData.length) {
        subdivisionItems.push(...this.issueFieldData.filter(item =>
          !selectedDimensionValue ||
          (item.value?.selected !== selectedDimensionValue || item.value?.type !== selectedDimensionType)))
      }
      subdivisionItems.push({
        divider: true
      }, {
        header: this.$t("2299")
      })
      if (this.reportFormData.length) {
        subdivisionItems.push(...this.reportFormData.filter(item =>
          !selectedDimensionValue ||
          (item.value?.selected !== selectedDimensionValue || item.value?.type !== selectedDimensionType)
        ))
      }
      subdivisionItems.push({
        divider: true
      }, {
        header: this.$t("2300")
      })
      if (this.issueFormData.length) {
        subdivisionItems.push(...this.issueFormData.filter(item =>
          !selectedDimensionValue ||
          (item.value?.selected !== selectedDimensionValue || item.value?.type !== selectedDimensionType)
        ))
      }
      return subdivisionItems
    },
    showTimeframeInput() {
      return this.chart.type === CHART_TYPES.LINE && this.chart.dimension &&
        this.chart.dimension.type === CHART_DIMENSION_TYPES.PROPERTY
        && this.chart.dimension.selected === ISSUE_PROPERTIES_FOR_CHART.DATE
    },
    isChartTypeDoughnut() {
      return this.chart.type === CHART_TYPES.DOUGHNUT
    },
    showSubdivision() {
      return !this.isChartTypeDoughnut
    },
    isChartNameChanged() {
      return this.chart.name !== this.pChartToEdit.name
    },
    isChartSavedFilterChanged() {
      return this.chart.savedFilterId !== this.pChartToEdit.savedFilterId
    },
    isChartTypeChanged() {
      return this.chart.type !== this.pChartToEdit.type
    },
    isChartMetricChanged() {
      return this.chart.metric !== this.pChartToEdit.metric
    },
    isChartDimensionChanged() {
      return this.chart.dimension.type !== this.pChartToEdit.dimension.type ||
        this.chart.dimension.selected !== this.pChartToEdit.dimension.selected
    },
    isChartTimeframeChanged() {
      return this.timeframe !== this.pChartToEdit.dimension.timeframe
    },
    isChartSubdivisionChanged() {
      return this.chart.subdivision?.type !== this.pChartToEdit.subdivision?.type ||
        this.chart.subdivision?.selected !== this.pChartToEdit.subdivision?.selected
    },
    isChartChanged() {
      return this.isChartNameChanged ||
        this.isChartSavedFilterChanged ||
        this.isChartTypeChanged ||
        this.isChartMetricChanged ||
        this.isChartDimensionChanged ||
        this.isChartTimeframeChanged ||
        this.isChartSubdivisionChanged
    }
  },
  methods: {
    handleCancel() {
      this.$emit("closeChartForm")
    },
    handleFormSubmit() {
      if (this.pIsEditMode){
        this.$emit("updateChart", this.getChartPropertiesToUpdate())
      } else {
        if (this.timeframe){
          this.chart.dimension.timeframe = this.timeframe
        }
        this.chart.subdivision = this.chart.subdivision || undefined
        this.$emit("addChart", this.chart)
      }
    },
    handleDimensionInput() {
      if (this.chart.subdivision) {
        const { type, selected } = this.chart.subdivision
        const dimensionValue     = this.chart.dimension?.selected

        if (Object.values(CHART_DIMENSION_TYPES).includes(type) && selected === dimensionValue) {
          this.chart.subdivision = undefined
        }
      }
    },
    checkIfFieldOfTypeOptionListAndBoolean(fieldId) {
      const field = this.fieldsMap[fieldId]
      return ALLOWED_FIELD_TYPES_FOR_CHART_DIMENSIONS_AND_SUBDIVISIONS.includes(field.type)
    },
    processFormTemplates(formTemplates) {
      const formData = []
      for (const formTemplate of formTemplates) {
        const formTemplateConfigsOfFormTemplate = this.pFormTemplateConfigurations.filter(eachFormTemplateConfig =>
          eachFormTemplateConfig.formTemplateId === formTemplate.id &&
          this.checkIfFieldOfTypeOptionListAndBoolean(eachFormTemplateConfig.fieldId)
        )

        formData.push({
          header: formTemplate.name
        })

        formTemplateConfigsOfFormTemplate.forEach(formTemplateConfigOfFormTemplate => {
          const fieldId    = formTemplateConfigOfFormTemplate.fieldId
          const fieldLabel = this.fieldsMap[fieldId].label
          formData.push({
            name : `${fieldLabel} (${formTemplate.name})`,
            value: {
              type    : CHART_DIMENSION_TYPES.FORM_FIELD,
              selected: formTemplateConfigOfFormTemplate.id
            }
          })
        })
      }
      return formData
    },
    getChartPropertiesToUpdate() {
      const updatedProperties = { id: this.chart.id }
      const propertiesToCheck = ["name", "savedFilterId", "type", "metric"]

      for (const properties of propertiesToCheck){
        if (this.chart[properties] !== this.pChartToEdit[properties]) {
          updatedProperties[properties] = this.chart[properties]
        }
      }

      const isSubdivisionChanged =
        this.chart.subdivision?.selected !== this.pChartToEdit.subdivision?.selected ||
        this.chart.subdivision?.type !== this.pChartToEdit.subdivision?.type

      if (isSubdivisionChanged && !updatedProperties.type) {
        updatedProperties.subdivision = this.chart.subdivision
      }

      const dimensionChanged =
        this.chart.dimension.type !== this.pChartToEdit.dimension.type ||
        this.chart.dimension.selected !== this.pChartToEdit.dimension.selected ||
        this.timeframe !== this.pChartToEdit.dimension.timeframe

      if (dimensionChanged && !updatedProperties.type) {
        updatedProperties.dimension = {
          type    : this.chart.dimension.type,
          selected: this.chart.dimension.selected,
          ...((this.timeframe) && { timeframe: this.timeframe })
        }
      }

      if (updatedProperties.type) {
        updatedProperties.metric      = this.chart.metric
        updatedProperties.dimension   = {
          type    : this.chart.dimension.type,
          selected: this.chart.dimension.selected,
          ...((this.timeframe) && { timeframe: this.timeframe })
        }
        updatedProperties.subdivision = this.chart.subdivision
      }

      return updatedProperties
    }
  },
  watch: {
    "chart.name": {
      handler: function() {
        if (this.isChartNameDuplicate) {
          if (this.pIsEditMode){
            this.$emit("resetChartNameUpdateError")
          } else {
            this.$emit("resetChartAddError")
          }
        }
      }
    },
    "chart.type": {
      handler: function(){
        if (this.chart.dimension && this.timeframe) {
          if (!(this.chart.type === CHART_TYPES.LINE
            && this.chart.dimension.type === CHART_DIMENSION_TYPES.PROPERTY
            && this.chart.dimension.selected === ISSUE_PROPERTIES_FOR_CHART.DATE)) {
            this.timeframe = undefined
          }
        }
        if (this.chart.subdivision) {
          if (this.chart.type === CHART_TYPES.DOUGHNUT) {
            this.chart.subdivision = undefined
          }
        }
      }
    },
    "chart.dimension.selected": {
      handler: function(newValue) {
        if (newValue !== ISSUE_PROPERTIES_FOR_CHART.DATE && this.timeframe) {
          this.timeframe = undefined
        }
      }
    },
    pChartAddError: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue?.field === "name, customViewId" && newValue?.type === "duplicate") {
          this.isChartNameDuplicate = true
        } else {
          this.isChartNameDuplicate = false
        }
      }
    },
    pChartNameUpdateError: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue?.field === "name, customViewId" && newValue?.type === "duplicate") {
          this.isChartNameDuplicate = true
        } else {
          this.isChartNameDuplicate = false
        }
      }
    },
    pChartToEdit: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.chart     = JSON.parse(JSON.stringify(newValue))
          this.timeframe = this.chart.dimension.timeframe
          delete this.chart.dimension.timeframe
        }
      }
    }
  }
}