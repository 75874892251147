import ThemisDecision from "@/components/shared/decision"
import {
  DATA_EXPORT_POLL_INTERVAL,
  DATA_EXPORT_STATUS,
  DATA_EXPORT_TYPE,
  NULL
} from "@/constants"

export default {
  name      : "Export",
  components: {
    ThemisDecision
  },
  data() {
    return {
      showAnalyticsExportDownloadDialog: false,
      exportStatusPolling              : NULL
    }
  },
  props: {
    pIsAddingExport       : Boolean,
    pExports              : Array,
    pLatestAnalyticsExport: Object
  },

  beforeDestroy() {
    clearInterval(this.exportStatusPolling)
  },

  computed: {
    latestAnalyticsExportStatus() {
      return this.pLatestAnalyticsExport?.status
    },
    isAnalyticsExportInProgress() {
      return this.pIsAddingExport || this.isAnalyticsExportInitiated
    },
    isAnalyticsExportCompleted() {
      return !this.pIsAddingExport && this.latestAnalyticsExportStatus === DATA_EXPORT_STATUS.COMPLETED
    },
    isAnalyticsExportInitiated() {
      return this.latestAnalyticsExportStatus === DATA_EXPORT_STATUS.INITIATED
    },
    analyticsExportCreatedAt() {
      return this.pLatestAnalyticsExport && this.$moment(this.pLatestAnalyticsExport.createdAt).format("DD MMMM YYYY HH:mm (UTCZ)")
    }
  },
  methods: {
    exportAnalytics() {
      this.$emit("addExport", {
        type: DATA_EXPORT_TYPE.ANALYTICS
      })
    },
    handleAnalyticsExportDownload() {
      this.$emit("downloadDataExport", { latestExport: this.pLatestAnalyticsExport })
      this.showAnalyticsExportDownloadDialog = false
    },
    clearExportStatusPollingInterval() {
      if (this.exportStatusPolling) {
        clearInterval(this.exportStatusPolling)
        this.exportStatusPolling = NULL
      }
    }
  },
  watch: {
    latestAnalyticsExportStatus: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          if (newValue === DATA_EXPORT_STATUS.INITIATED) {
            this.exportStatusPolling = setInterval(() =>
              this.$emit("loadExport", this.pLatestAnalyticsExport.id),
            DATA_EXPORT_POLL_INTERVAL)
          }

          if (newValue === DATA_EXPORT_STATUS.COMPLETED || newValue === DATA_EXPORT_STATUS.FAILED) {
            this.clearExportStatusPollingInterval()
          }
        }
      }
    }
  }
}