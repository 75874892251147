const fontFamily = "Nunito"
import { format } from "echarts/core"

const colors = ["#003026", "#006646", "#00B687", "#B8D0EB", "#B95F89", "#E49D9D"]

const LABEL_SKIP_START_LIMIT = 30
const DEGREES_45             = 45

// Function to convert hex color to RGB
const hexToRgb = hex => {
  const red = parseInt(hex.slice(1, 3), 16),
        green = parseInt(hex.slice(3, 5), 16),
        blue = parseInt(hex.slice(5, 7), 16)

  return [red, green, blue]
}

// Function to convert RGB color to hex
const rgbToHex = (red, green, blue) => {
  return "#" + ((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1)
}

// Function to generate color variants
const generateColorVariants = (baseColors, numberOfColorVariants) => {
  const iterations = Math.ceil(numberOfColorVariants / (baseColors.length * 2))

  const lighterVariants = []
  const darkerVariants  = []

  for (let iterator = 1; iterator <= iterations; iterator++) {
    const variation = iterator * (Math.floor(Math.random() * 10) + 1)
    baseColors.forEach(color => {
      const [red, green, blue] = hexToRgb(color)

      const lighter = rgbToHex(
        Math.min(red + variation, 255),
        Math.min(green + variation, 255),
        Math.min(blue + variation, 255)
      )

      const darker = rgbToHex(
        Math.max(red - variation, 0),
        Math.max(green - variation, 0),
        Math.max(blue - variation, 0)
      )

      lighterVariants.push(lighter)
      darkerVariants.push(darker)
    })
  }
  return [
    ...baseColors,
    ...lighterVariants,
    ...darkerVariants
  ]
}

export const getBarChartOptions = ({
  categories,
  numberOfColorVariants = 0,
  horizontal = false,
  hasSubdivision = false,
  series = [],
  compareToPreviousData,
  dateRangeText
}) => {
  const getBorders = index => {
    const bordersInBarGraph = horizontal ? [0, 4, 4, 0] : [4, 4, 0, 0]
    return index === series.length - 1 ? bordersInBarGraph : [0, 0, 0, 0]
  }

  const getBottomPadding = () =>  {
    let padding = 1
    if (horizontal) {
      padding += 15
    } else {
      if (compareToPreviousData) {
        padding += 65
      }
      if (hasSubdivision)  {
        padding += compareToPreviousData ? 1 : 40
      }
    }
    return padding
  }

  const getTopPadding = () => {
    if (horizontal) {
      if (hasSubdivision) {
        return compareToPreviousData ? 70 : 30
      }
      return compareToPreviousData ? 70 : 20
    } else {
      return 30
    }
  }

  const getTitle = () => {
    if (!compareToPreviousData) return

    return {
      text     : dateRangeText,
      left     : "center",
      textStyle: {
        fontFamily,
        fontWeight: "normal",
        fontSize  : 20
      },
      ...(horizontal ? { top: "30" } : { bottom: "5%" })
    }
  }

  const options = {
    legend: {
      type: "scroll",
      show: hasSubdivision || compareToPreviousData,
      ...(horizontal ? { top: 0 } : { bottom: 0 })
    },
    title  : getTitle(),
    color  : generateColorVariants(colors, numberOfColorVariants),
    tooltip: {
      confine    : true,
      trigger    : "item",
      axisPointer: {
        type: "shadow"
      },
      formatter: function(params) {
        const name       = format.encodeHTML(params.name)
        const seriesName = format.encodeHTML(params.seriesName)
        const value      = format.encodeHTML(params.value)
        return `${name}<br/>${params.marker} ${seriesName}: ${value}`
      }
    },
    grid: {
      top         : getTopPadding(),
      left        : "3%",
      right       : "4%",
      bottom      : getBottomPadding(),
      containLabel: true
    },
    xAxis: {
      type     : horizontal ? "value" : "category",
      data     : categories,
      axisLabel: {
        show        : true,
        interval    : categories.length > LABEL_SKIP_START_LIMIT ? "auto" : 0,
        rotate      : horizontal ? 0 : DEGREES_45,
        fontFamily,
        width       : 100,
        overflow    : "truncate",
        showMaxLabel: true
      },
      position: horizontal ? "top" : "bottom",
      stacked : hasSubdivision,
      tooltip : {
        show     : true,
        formatter: function(params) {
          return format.encodeHTML(params.value)
        }
      }
    },
    yAxis: {
      type     : horizontal ? "category" : "value",
      data     : categories,
      axisLabel: {
        show    : true,
        interval: categories.length > LABEL_SKIP_START_LIMIT ? "auto" : 0,
        fontFamily,
        width   : 100,
        overflow: "truncate"
      },
      tooltip: {
        show      : true,
        renderMode: "html",
        confine   : true,
        formatter : function(params) {
          return format.encodeHTML(params.value)
        }
      },
      stacked: hasSubdivision
    },
    series: series.map((seriesItem, index) => ({
      stack    : hasSubdivision,
      name     : seriesItem.name,
      type     : "bar",
      data     : seriesItem.data,
      itemStyle: {
        borderRadius: getBorders(index)
      }
    }))
  }

  // Can not be directly put in the options
  // For some reason breaks the axis line for Vertical charts
  if (horizontal) {
    options.xAxis.axisLine = {
      show: true
    }
  }

  return options
}

export const getHorizontalBarChartHeight = xAxisItemsLength =>
  xAxisItemsLength < 5 ? 250 : xAxisItemsLength * window.innerWidth / 40

export const getLineChartOptions = ({
  categories,
  numberOfColorVariants = 0,
  compareToPreviousData,
  series = [],
  dateRangeText = undefined,
  hasSubdivision = false
}) => {
  let colorsForLineChart = generateColorVariants(colors, numberOfColorVariants)
  if (compareToPreviousData) {
    colorsForLineChart = colorsForLineChart.slice(0, numberOfColorVariants)
    colorsForLineChart = colorsForLineChart.concat(colorsForLineChart)
  }

  const getLineStyle = group => {
    const lineStyleContent = {
      type: group && group === "previousDate" ? "dashed" : "solid"
    }
    return lineStyleContent
  }

  const gridBottom = (() => {
    const titleHeight   = compareToPreviousData ? 30 : 0
    const legendHeight  = (hasSubdivision || compareToPreviousData) ? 20 : 0
    const bottomPadding = (hasSubdivision || compareToPreviousData) ? 20 : 0
    const padding       = bottomPadding + titleHeight + legendHeight
    return padding
  })

  const options = {
    grid: {
      bottom      : gridBottom(),
      containLabel: true
    },
    title: compareToPreviousData ? {
      text     : dateRangeText,
      left     : "center",
      bottom   : 30,
      textStyle: {
        fontFamily,
        fontWeight: "normal",
        fontSize  : 20
      }
    } : undefined,
    color  : colorsForLineChart,
    tooltip: {
      trigger    : "axis",
      axisPointer: {
        type: "line"
      },
      formatter: function(params) {
        const axisValue      = params[0].axisValue
        const filteredParams = params.filter(param => param.value !== 0)

        if (filteredParams.length === 0) return ""

        let result = `${axisValue}<br/>`
        filteredParams.forEach(param => {
          const seriesName = format.encodeHTML(param.seriesName)
          const value      = format.encodeHTML(param.value)
          result          += `${param.marker} ${seriesName}: ${value}<br/>`
        })
        return result
      }
    },
    xAxis: [{
      type    : "category",
      data    : categories,
      axisTick: {
        alignWithLabel: true
      },
      axisLabel: {
        show        : true,
        interval    : categories.length > LABEL_SKIP_START_LIMIT ? "auto" : 0,
        rotate      : DEGREES_45,
        fontFamily,
        width       : 100,
        overflow    : "truncate",
        showMaxLabel: true
      },
      tooltip: {
        show     : true,
        formatter: function(params) {
          return format.encodeHTML(params.value)
        }
      }
    }],
    yAxis: [{
      type: "value"
    }],
    series: series.map(seriesItem => ({
      name     : seriesItem.name,
      type     : "line",
      data     : seriesItem.data,
      lineStyle: {
        ...getLineStyle(seriesItem.group)
      }
    }))
  }

  if (hasSubdivision || compareToPreviousData) {
    const dataForLabels = series.map(seriesItem => seriesItem.name)
    options.legend      = {
      type     : "scroll",
      data     : dataForLabels,
      bottom   : 0,
      textStyle: {
        fontFamily,
        fontWeight: "normal",
        fontSize  : 15
      }
    }
  }

  return options
}

export const getDoughnutChartOptions = ({
  series = [],
  numberOfColorVariants = 0,
  isScreenLgAndDown = false
}) => {
  const totalCount = result => {
    return result.reduce((sum, item) => sum + item.value, 0)
  }

  const getGridLayout = count => {
    if (count === 1) return [["50%", "50%"]]
    else return [
      ["25%", "50%"],
      ["75%", "50%"]
    ]
  }

  const getTitleConfig = (index, totalCharts) => {
    if (totalCharts === 1) {
      return {
        left     : "50%",
        bottom   : "0",
        textAlign: "center",
        textStyle: {
          fontFamily,
          fontWeight: "normal",
          fontSize  : 20
        }
      }
    }
    return {
      left     : index === 0 ? "25%" : "75%",
      textAlign: "center",
      bottom   : isScreenLgAndDown ? "30" : "0",
      textStyle: {
        fontFamily,
        fontWeight: "normal",
        fontSize  : 20
      }
    }
  }

  const getLegendConfig = (series, index) => {
    const legendOrient = isScreenLgAndDown ? "horizontal" : "vertical"
    const legendBottom = isScreenLgAndDown ? "0" : undefined

    const config = {
      orient      : legendOrient,
      bottom      : legendBottom,
      selectedMode: true,
      type        : "scroll",
      textStyle   : {
        fontFamily,
        overflow: "truncate",
        width   : 140
      },
      ...(isScreenLgAndDown
        ? {
          width: series.length === 1 ? "90%" : "40%",
          left : series.length === 1 ? "center" : (index === 0 ? "5%" : "50%")
        }
        : {
          right: series.length === 1 ? "5%" : (index === 0 ? "50%" : "5%")
        }
      )
    }
    return config
  }

  const filteredSeries = series.filter(seriesItem => seriesItem.dateTime)

  const option = {
    grid: {
      containLabel: true,
      left        : "5%",
      right       : "25%",
      bottom      : 40
    },
    title: filteredSeries.length > 0 ? filteredSeries.map((seriesItem, index) => ({
      text: seriesItem.dateTime,
      ...getTitleConfig(index, filteredSeries.length)
    })) : undefined,
    color  : generateColorVariants(colors, numberOfColorVariants),
    tooltip: {
      trigger  : "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)"
    },
    legend: series.map((seriesItem, index) => ({
      data   : seriesItem.data.map(item => item.name),
      ...getLegendConfig(series, index),
      tooltip: {
        show     : true,
        formatter: function(params) {
          return format.encodeHTML(params.name)
        }
      }
    })),
    series: [
      ...series.flatMap((seriesItem, index) => {
        const center = getGridLayout(series.length)[index]
        return [{
          name             : seriesItem.name,
          type             : "pie",
          radius           : ["50%", "70%"],
          center,
          avoidLabelOverlap: false,
          label            : {
            show    : false,
            position: "center"
          },
          emphasis: {
            label: {
              show: false
            }
          },
          labelLine: {
            show: false
          },
          data: totalCount(seriesItem.data) ? seriesItem.data : []
        }, {
          name   : "Total",
          type   : "pie",
          radius : ["0%", "30%"],  // Makes it a smaller inner circle
          center,
          tooltip: {
            show: false
          },
          label: {
            position : "center",
            show     : true,
            formatter: () => {
              const total = totalCount(seriesItem.data)
              return format.encodeHTML(total)
            },
            fontSize  : 48,
            fontWeight: "bold"
          },
          data: [{
            value    : 1,
            name     : "Total",
            itemStyle: {
              color: "transparent"
            }
          }]
        }]
      })
    ]
  }

  return option
}